// Mixins
import Vue from 'vue';
/**
 * gallery
 * @mixin
 *
 * Used to add common gallery functionality to components
 */

export default Vue.extend({
    name: 'galleryMixin',
    data(){
        return{
            galleryImages: [],
            galleryIndex: null
        }
    },
    methods:{
        openGallery(arr, index = 0){
            this.galleryImages = arr.map(m => {return {
                href: this.imgWpx(m.image, 900),
                type: 'image/' + m.image.split('.').pop()
            }});
            this.galleryIndex = index
        }
    }

});