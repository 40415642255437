<template>
  <div id="Customers">
    <v-container fluid>
      <v-card class="elevation-0 px-1 mb-5">
        <v-card-title>
          <v-row>
            <v-col class="pa-0 d-flex align-center" cols="auto">
              <div class="d-flex pa-0 mb-1 mr-3">
                <div @click="filterDistributors('all')" class="count-widget-all px-3 py-2 mr-2"
                     :class="selectedFilter==='all'?'count-widget-all-active':''">
                  <p class="text-body-2 mb-0">All Customers</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ tempCustomers.length }}</h4>
                </div>
                <div @click="filterDistributors('assigned')" class="count-widget-assign px-3 py-2"
                     :class="selectedFilter==='assigned'?'count-widget-assign-active':''">
                  <p class="text-body-2 mb-0">Assigned Customers</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ AssignedRoutes }}</h4>
                </div>
                <div @click="filterDistributors('unassigned')" class="count-widget px-3 py-2 ml-2"
                     :class="selectedFilter==='unassigned'?'count-widget-active':''">
                  <p class="text-body-2 mb-0">Unassigned Customers</p>
                  <h4 class="text-subtitle-1 font-weight-bold">{{ UnAssignedRoutes }}</h4>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="3">
              <v-text-field dense clearable label="Search Customers" outlined v-model="search"></v-text-field>
            </v-col>
            <v-col class="pa-0 ml-2" cols="auto">
              <v-btn v-if="permissions.permissionMeta.create" depressed @click="startCreateCustomer" class="primary">
                ADD CUSTOMER
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col cols="12" class="px-2 pt-0 ">
          <v-data-table fixed-header :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page="25"
                        @click:row="openCustomerDetail" :loading="loading" :search="search" class="elevation-0"
                        :headers="customersHeaders" height="70vh" :items="customers">
            <template v-slot:no-data>No Customers Available</template>
            <template v-slot:no-results>No Customers Available</template>
            <template v-slot:item.companyName="{ item }">
              {{ checkVal(item.name) }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.Users.length ? checkVal(item.Users[0].name) : '-' }}
            </template>
            <template v-slot:item.mobile="{ item }">
              {{ checkVal(item.mobile) }}
            </template>
            <template v-slot:item.whatsappNumber="{ item }">
              {{ checkVal(item.whatsappNumber) }}
            </template>
            <template v-slot:item.zone="{ item }">
              {{ checkVal(item.zone) }}
            </template>
            <template v-slot:item.totalDueAmount="{ item }">
              {{ item.totalDueAmount ? `₹ ${item.totalDueAmount}` : '-' }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.Type ? item.Type.type : '-' }}
            </template>
            <template v-slot:item.address="{ item }">
              <template v-if="item.Address">
                <div><span v-if="item.Address.area">{{ item.Address.area }},</span></div>
                <div>{{ item.Address.city }}, {{ item.Address.state }},
                  {{ item.Address.country }}
                </div>
              </template>
              <template v-else>-</template>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center">
                <v-btn v-if="permissions.permissionMeta.update" @click.stop="onClickAction(actionArr[0],item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
<!--                <v-switch v-if="permissions.permissionMeta.delete" hide-details class="mt-0"-->
<!--                          @click.stop="onClickAction(actionArr[1],item)"-->
<!--                          :input-value="item.status === 'ACTIVE'"></v-switch>-->
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <CreateUpdateOrganization @showToast="showToaster" :org="customer" @cancel="saveCustomerDialog=false"
                              @saveOrganization="saveCustomer" v-if="saveCustomerDialog"
                              :saveOrganizationDialog="saveCustomerDialog"></CreateUpdateOrganization>
    <OrganizationDetail :permissions="permissions" @showToaster="showToaster" @cancel="organizationDetailDialog=false"
                        :organizationDetailDialog="organizationDetailDialog" @saveOrganization="saveCustomer"
                        v-if="organizationDetailDialog" :organizationId="customer.id"></OrganizationDetail>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false;confirmMsg = 'Confirm! '"
                        @confirm="confirmAction" v-if="confirmationDialog"></ConfirmationDialog>
    <Snackbar :toaster="toaster"></Snackbar>
  </div>
</template>

<script>
import { IDS } from '@/Constants/IDS'
import { SUBSCRIBE_CUSTOMERS } from '../graphql/subscriptions/Organizations'
import CreateUpdateOrganization from '../components/Organizations/CreateUpdateOrganization'
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import Snackbar from '@/components/Common/Snackbar'
import { SOFT_DELETE_ORGANIZATION } from '@/graphql/mutations/Organizations'
import OrganizationDetail from '../components/Organizations/OrganizationDetail'

export default {
  name: 'Customers',
  components: { OrganizationDetail, Snackbar, ConfirmationDialog, CreateUpdateOrganization },
  data () {
    return {
      customers: [], tempCustomers: [], search: null, saveCustomerDialog: false, viewDistributorDialog: false,
      AssignedRoutes: 0, UnAssignedRoutes: 0, organizationDetailDialog: false, selectedAction: {},
      customer: null, toaster: {}, confirmMsg: 'Confirm! ', loading: true, confirmationDialog: false,
      customersHeaders: [
        { text: 'Company Name', value: 'companyName' }, { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Type', value: 'type' }, { text: 'Address', value: 'address' },
        { text: 'Actions', value: 'actions', sortable: false },
      ], selectedFilter: 'all', permissions: {},
      actionArr: [{ id: '1', title: 'Edit' }, { id: '2', title: 'Delete' }],
    }
  },
  apollo: {
    $subscribe: {
      Customers: {
        query: SUBSCRIBE_CUSTOMERS, fetchPolicy: 'network-only',
        variables () {
          return {
            orgType: [IDS.organizationTypes.Retailer, IDS.organizationTypes.Dealer],
            userType: IDS.userTypes.Customer,
          }
        },
        result ({ data }) {
          this.customers = JSON.parse(JSON.stringify(data.Customers))
          this.tempCustomers = JSON.parse(JSON.stringify(data.Customers))
          this.AssignedRoutes = 0
          this.UnAssignedRoutes = 0
          this.customers.forEach(customer => {
            if (customer.RouteShops_aggregate.aggregate.count) this.AssignedRoutes++
            else this.UnAssignedRoutes++
          })
          this.loading = false
        },
      },
    },
  },
  beforeMount () {
    this.permissions = this.$store.getters.permissionByScreen('Customers')
  },
  methods: {
    onClickAction (selectedAction, selectedUser) {
      this.customer = selectedUser
      this.selectedAction = this.actionArr.filter(action => selectedAction.id === action.id)[0]
      if (this.selectedAction.id === '1')
        this.confirmAction()
      else if (this.selectedAction.id === '2')
        this.confirmMsg = this.confirmMsg.concat('this will update customer status, are you sure?')
      this.confirmationDialog = this.selectedAction.id === '2'
    },
    confirmAction () {
      this.confirmationDialog = false
      this.confirmMsg = 'Confirm! '
      if (this.selectedAction.id === '1')
        this.editCustomer()
      else if (this.selectedAction.id === '2')
        this.deleteCustomer()
    },
    startCreateCustomer () {
      this.customer = null
      this.saveCustomerDialog = true
    },
    openCustomerDetail (customer) {
      this.customer = customer
      this.organizationDetailDialog = true
    },
    saveCustomer () {
      this.showToaster({ enable: true, color: 'green', message: `Customer saved successfully` })
      this.saveCustomerDialog = false
    },
    editCustomer () { this.saveCustomerDialog = true },
    async deleteCustomer () {
      let response = await this.$apollo.mutate({
        mutation: SOFT_DELETE_ORGANIZATION,
        variables: {
          id: this.customer.id, status: this.customer.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
        },
      })
      if (response.data)
        this.showToaster({ enable: true, color: 'green', message: 'Customer successfully updated' })
    },
    viewDistributor () {this.viewDistributorDialog = true},
    filterDistributors (filterType) {
      this.loading = true
      this.customers = []
      this.selectedFilter = filterType
      if (this.selectedFilter === 'assigned') {
        this.tempCustomers.forEach(customer => {
          if (customer.RouteShops_aggregate.aggregate.count) this.customers.push(customer)
        })
      } else if (this.selectedFilter === 'unassigned') {
        this.tempCustomers.forEach(customer => {
          if (!customer.RouteShops_aggregate.aggregate.count) this.customers.push(customer)
        })
      } else this.customers = this.tempCustomers
      this.loading = false
    },
    showToaster (data) {
      this.toaster = data
    },
  },
}
</script>
<style scoped>
/deep/ tr:hover {
  cursor: pointer !important;
}
</style>
