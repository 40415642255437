import gql from 'graphql-tag'
import {IDS} from "../../Constants/IDS";

export const GET_FILTERED_ORGANIZATIONS = gql`
    query ($orgIds:[uuid!],$orgType:[uuid!]){
        Organizations: Organizations(where: {id: {_nin: $orgIds}, type:{_is_null:false,_nin:$orgType}}, order_by: {name: asc}){
            id
            name
            mobile
            zone
            lat
            long
            Type{
                id
                type
            }
        }
    }`

export const GET_ORGANIZATION_USER_BY_MOBILE = gql`
    query($userId:uuid!,$organizationId:uuid!,$mobile:String!){
        Organizations(where:{id:{_neq:$organizationId}, mobile:{_eq:$mobile}}){
            id
        },
        Users(where:{id:{_neq:$userId}, mobile:{_eq:$mobile}}){
            id
        }
    }`

export const GET_ORGANIZATION_BY_MOBILE = gql`
    query($mobile:String!){
        SuggetionOrganizations(where:{mobile:{_eq:$mobile}}){
            id
            name
            shopType
            gstInNumber
            status
            mobile
            whatsappNumber
            zone
            lat
            long
            totalDueAmount
            fssaiCode
            fssaiCodeExpiryDate
            Address{
                id
                address1
                address2
                area
                city
                state
                pincode
                country
            }
            Type{
                id
                type
            }
            Users(where:{userTypeId:{_eq:"121380be-fde4-4b3b-a6aa-98b543d529cf"}}){
                id
                name
                mobile
                email
                userTypeId
                birthDate
                anniversary
            }
        }
    }`

export const GET_ORGANIZATIONS_BY_TYPE = gql`
    query ($orgType:[uuid!]){
        Distributors:Organizations(where:{ type:{ _in: $orgType}}, order_by:{created_at:desc}){
            id
            name
            mobile
            orgCode
            Routes{
                id
            }
        }
    }`
export const GET_DISTRIBUTORS_FOR_REPORT = gql`
query ($orgType:[uuid!]){
  Distributors:Organizations(where:{ type:{ _in: $orgType}}, order_by:{created_at:desc}){
    id
    name
    mobile
    Users(where:{userTypeId:{_in:["${IDS.userTypes.Owner}"]},
                 isKeyOwner:{_eq:true}}){ id name mobile }
  }
}`

export const GET_ORGANIZATIONS_BY_TYPE_AND_ASSIGNED_TO = gql`
query getOrgByTypeAndAssignTo($orgType:[uuid!], $assignTo:[uuid!]){
    Distributors: Organizations(where:{ type:{ _in: $orgType}, SalesExecutives:{ salesExecutiveId:{ _in: $assignTo}}}, order_by:{created_at:desc}){
        id
        name
        mobile
        Users(where:{userTypeId:{_in:["${IDS.userTypes.Owner}"]},
                 isKeyOwner:{_eq:true}}){ id name mobile }            
    }
}`
export const GET_ORGANIZATIONS_WITH_OWNER_BY_TYPE = gql`
    query ($orgType:[uuid!]){
        Distributors:Organizations(where:{ type:{ _in: $orgType}}, order_by:{created_at:desc}){
            id
            name
            mobile
            orgCode
            Routes{ id }
            Users(where:{userTypeId:{_eq:"${IDS.userTypes.Owner}"},isKeyOwner:{_eq:true}}){ id }
        }
    }`

export const GET_FILTERED_DISTRIBUTOR = gql`
    query ($orgId:[uuid!],$orgType:[uuid!]){
        Distributors:Organizations(where:{ id:{ _nin: $orgId}, type:{ _in: $orgType}}){
            id
            name
            mobile
            orgCode
        }
    }`

export const GET_DISTRIBUTOR_RTO_CODES = gql`
query getDistributorRtoCodes{
    rtoCodes:RTOCode(order_by:{created_at:asc}){
        id
        name            
        city
        state
    }
}`
