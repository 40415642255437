<template>
  <div id="OrganizationDetail">
    <v-dialog fullscreen hide-overlay persistent v-model="organizationDetailDialog">
      <v-card v-if="organization" style="background: #e9ecee">
        <v-toolbar style="box-shadow: 0 15px 40px -20px rgba(40,44,63,.15);">
          <v-container class="d-flex align-center">
            <v-toolbar-title>Customer Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('cancel')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-container>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-card style="box-shadow: 0 15px 40px -20px rgba(40,44,63,.15);" class="pa-8 rounded-0">
                  <div class="mb-1 d-flex justify-space-between align-start">
                    <h2 class="font-weight-medium "> {{ checkVal(organization.name) }}</h2>
                    <v-btn v-if="permissions.permissionMeta.update" class="float-right" fab icon x-small
                           @click="saveCustomerDialog=true">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-body-2 mb-6 grey--text text--darken-2" v-if="organization.Address">
                    <div v-if="organization.Address.address1">{{ organization.Address.address1 }},</div>
                    <div v-if="organization.Address.address2">{{ organization.Address.address2 }},</div>
                    <div v-if="organization.Address.area">{{ organization.Address.area }},</div>
                    <div>
                      <span v-if="organization.Address.city">{{ organization.Address.city }} - </span>
                      <span v-if="organization.Address.pincode">{{ organization.Address.pincode }},</span>
                      <span v-if="organization.Address.state">{{ organization.Address.state }},</span>
                      <span v-if="organization.Address.country">{{ organization.Address.country }}</span>
                    </div>
                    <div v-if="organization.lat && organization.long">Lat - {{ organization.lat }} , Long -
                      {{ organization.long }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Type</span>
                    <span class="grey--text text--darken-2">{{ checkVal(organization.Type.type) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Mobile</span>
                    <span class="grey--text text--darken-2">{{ checkVal(organization.mobile) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">GSTIN</span>
                    <span class="grey--text text--darken-2">{{ checkVal(organization.gstInNumber) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Shop Type</span>
                    <span class="grey--text text--darken-2">{{ checkVal(organization.shopType) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">FSSAI Code</span>
                    <span class="grey--text text--darken-2">{{ checkVal(organization.fssaiCode) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="red--text text--darken-2">Total Due Amount</span>
                    <span class="red--text text--darken-2">
                      {{ organization.totalDueAmount ? `₹ ${organization.totalDueAmount}` : '-' }}
                    </span>
                  </div>
                  <hr v-if="organization.Users.length" class="my-4">
                  <div v-if="organization.Users.length">
                    <div>
                      <h3 class="text-subtitle-1 font-weight-medium">{{ checkVal(organization.Users[0].name) }}</h3>
                    </div>
                    <div class="d-flex justify-space-between order-bill my-2">
                      <span class="grey--text text--darken-2">Email</span>
                      <span class="grey--text text--darken-2">{{ checkVal(organization.Users[0].email) }}</span>
                    </div>
                    <div class="d-flex justify-space-between order-bill my-2">
                      <span class="grey--text text--darken-2">Birthdate</span>
                      <span class="grey--text text--darken-2">
                        {{ dateFormatter(organization.Users[0].birthDate) }}</span>
                    </div>
                    <div class="d-flex justify-space-between order-bill my-2">
                      <span class="grey--text text--darken-2">Anniversary</span>
                      <span class="grey--text text--darken-2">
                        {{ dateFormatter(organization.Users[0].anniversary) }}</span>
                    </div>
                  </div>
                  <hr v-if="organization.Users.length" class="my-4">
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Created By</span>
                    <span class="grey--text text--darken-2">
                      {{ checkVal(organization.CreatedBy && organization.CreatedBy.name) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Created On</span>
                    <span class="grey--text text--darken-2">{{ dateTimeFilter(organization.created_at) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Updated By</span>
                    <span class="grey--text text--darken-2">
                      {{ checkVal(organization.UpdatedBy && organization.UpdatedBy.name) }}</span>
                  </div>
                  <div class="d-flex justify-space-between order-bill my-2">
                    <span class="grey--text text--darken-2">Updated On</span>
                    <span class="grey--text text--darken-2">{{ dateTimeFilter(organization.updated_at) }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-tabs v-model="selectedTab">
                  <v-tab v-for="(tab,index) in tabs" :key="index">{{ tab.label }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab">
                  <v-tab-item v-for="(tab,index) in tabs" :key="index">
                    <template v-if="tab.id===1">
                      <v-card>
                        <v-card-title>Shop Images</v-card-title>
                        <v-card-text>
                         <v-row>
                         <v-col cols="3" v-for="(a, index) in organization.Attachments" :key="index" class="img-div" @click.stop="openGallery(organization.Attachments, index)" title="View Image">
                            <v-img :src="imgWpx(a.image, 200)" aspect-ratio="1">
                             <!-- <template slot="placeholder">
                                <v-icon size="50" color="blue-grey lighten-5">mdi-image</v-icon>
                                <v-progress-circular width="3" size="24" indeterminate style="" color="deep-orange lighten-2"></v-progress-circular>
                              </template> -->
                            </v-img>
                          </v-col>
                         </v-row>
                          <div v-if="!organization.Attachments.length" class="mt-4">No Shop Images added yet.</div>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-if="tab.id===2">
                      <v-card height="475">
                        <v-card-title>{{ tab.label }}</v-card-title>
                        <v-card-subtitle>Coming Soon</v-card-subtitle>
                      </v-card>
                    </template>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CreateUpdateOrganization @showToast="$emit('showToaster');saveCustomerDialog=false" :org="organization"
                              @cancel="saveCustomerDialog=false" v-if="saveCustomerDialog"
                              @saveOrganization="$emit('saveOrganization');saveCustomerDialog=false"
                              :saveOrganizationDialog="saveCustomerDialog"></CreateUpdateOrganization>
    <gallery id="gallery-customer-detail" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
  </div>
</template>

<script>
import CreateUpdateOrganization from '@/components/Organizations/CreateUpdateOrganization'
import { SUBSCRIBE_CUSTOMER_BY_ID } from '../../graphql/subscriptions/Organizations'
import { IDS } from '@/Constants/IDS'
import gallery from '../../mixins/gallery';

export default {
  name: 'OrganizationDetail',
  components: { CreateUpdateOrganization },
  mixins: [gallery],
  props: {
    organizationId: { type: String, default: null },
    organizationDetailDialog: { type: [Boolean], default: false },
    permissions: { type: Object },
  },
  apollo: {
    $subscribe: {
      Organizations: {
        query: SUBSCRIBE_CUSTOMER_BY_ID, fetchPolicy: 'network-only',
        skip () {return !this.organizationId},
        variables () {return { id: this.organizationId, userType: IDS.userTypes.Customer }},
        result ({ data, loading }) { if (!loading) { this.organization = data.Organizations[0] } },
      },
    },
  },
  data () {
    return {
      tabs: [{ id: 1, label: 'More Details' }, { id: 2, label: 'Purchase' }], selectedTab: 0,
      saveCustomerDialog: false, organization: null,
    }
  },
}
</script>
<style>
  .img-div{
    cursor: pointer;
  }
</style>